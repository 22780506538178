import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// Image
import aboutImg from "../../assets/img/about/history.png";

const AboutTab = () => {
  let tab1 = "History",
    tab2 = "Mission",
    tab3 = "Vision";
  const tabStyle = "nav nav-tabs histort-part";

  return (
    <div className="rs-about style1  md-pt-70 md-pb-70">
      <div className="container">
        <Tabs>
          <div className="row align-items-center">
            <div className="col-lg-6 padding-0 md-pl-14 md-pr-14 md-mb-30 relative">
              <div className="img-part">
                <img src={aboutImg} alt="About Image" />
              </div>
              <TabList className={tabStyle}>
                <Tab>
                  <button>
                    <span className="icon-part">
                      <i className="flaticon-banknote"></i>
                    </span>
                    {tab1}
                  </button>
                </Tab>
                <Tab>
                  <button>
                    <span className="icon-part">
                      <i className="flaticon-flower"></i>
                    </span>
                    {tab2}
                  </button>
                </Tab>
                <Tab>
                  <button>
                    <span className="icon-part">
                      <i className="flaticon-analysis"></i>
                    </span>
                    {tab3}
                  </button>
                </Tab>
              </TabList>
            </div>
            <div className="col-lg-6 pl-120 md-pl-14">
              <TabPanel>
                <div className="about-history">
                  <div className="sec-title mb-24">
                    <h2 className="title">Syrol History</h2>
                    <div className="desc">
                      In the year 2019, MTN Nigeria's staff Aliyu Rabiu Abdul
                      popularly known as Rabal, graduated from NIMS University,
                      Jaipur Rajasthan India 2017 with First class division in
                      Information Technology. He furthered his education with a
                      second degree in Artificial intelligence from the
                      University of Zambia. While he was working with MTN, he
                      realized that Africa needs additional skilled people in
                      the tech industry which is why he began research in the
                      tech and learning space in the region. He started by
                      creating a free online learning room where people join him
                      to learn from his skills in Software Engineering. The
                      demands were high which is why he taught to set up a
                      proffer training company that could accommodate the people
                      that needed the skill at that time. The company named
                      Syrol Technologies Limited was then registered with CAC
                      and got the necessary licenses to start running in
                      Nigeria. The company started in Lagos, Nigeria, and
                      extended its branches to Abuja and Kano State.
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="about-mission">
                  <div className="sec-title mb-24">
                    <h2 className="title">Syrol Mission</h2>
                    <div className="desc">
                      Our core mission is to become a global brand, with a
                      recognized presence and an unmatched service model in many
                      countries of the world. Our primary goal is to use open
                      and understandable information technology systems to help
                      people and business owners build a sustainable business
                      model that fits into today’s digital marketplace while
                      empowering individuals with 21st-century technology
                      skills.
                      <p className="mt-3">
                        {" "}
                        Syrol Technologies is envisioned to become the premier
                        software research, development, and training firm in
                        Africa.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="about-admin">
                  <div className="sec-title mb-24">
                    <h2 className="title">Syrol Vision</h2>
                    <div className="desc">
                      Our Vision is to be the leading Software Solution, Job
                      Placement Network, and Hands-on Training company in the IT
                      sector and progress in our current position in the market.
                      We know that the Client’s growth is our growth, so we
                      commit to our clients to help them in achieving their
                      business goals. We believe in working with the accuracy
                      and best quality. We want to be known as a reliable,
                      innovative, and user-friendly software service provider in
                      the IT industry.
                      <p className="mt-3">
                        We create and innovate by bringing dreams and ideas into
                        reality with no default.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default AboutTab;
