import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleServiceSeven from "../../components/Service/SingleServiceSeven";

import serviceImg1 from "../../assets/img/services/home12/1.png";
import serviceImg2 from "../../assets/img/services/home12/2.png";
import serviceImg3 from "../../assets/img/services/home12/3.png";

import bgImg from "../../assets/img/bg/home12/services-bg.jpg";

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const Service = () => {
  return (
    <React.Fragment>
      <div
        id="rs-services"
        className="rs-services home12-style"
        style={bgStyle}
      >
        <div className="container">
          <SectionTitle
            sectionClass="sec-title4 text-center mb-50"
            subtitleClass="sub-title"
            subtitle="Know More About"
            titleClass="title"
            title="What we do"
          />
          <div className="row">
            <div className="col-lg-4 md-mb-30">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg1}
                serviceTitle="Software Development"
                serviceDesc="We Design & Develop Software in a way to get the desired results. Maximum results at minimum Cost prevailing in the Market"
              />
            </div>
            <div className="col-lg-4 md-mb-30">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg3}
                serviceTitle="Vocational Training"
                serviceDesc="We offer professional hands-on IT-related training and mentoring to governmental organizations, NGOs, and individuals from scratch to advance"
              />
            </div>
            <div className="col-lg-4">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg2}
                serviceTitle="Job Placement"
                serviceDesc="We train IT or non-IT students from zero to advance and secure a job for them in the tech industry. Over 187 students got jobs from our placement program."
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Service;
