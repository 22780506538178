import React, { useEffect, useState } from "react";
import { CourseReviewService } from "../../../services/CourseReviewService";
import { Link } from "react-router-dom";
import Loader from "../../../components/Common/Loader";
import Error from "../../../components/Error/Error";

const ReviewPart = (props) => {
  const [reviews, setReviews] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { course } = props;

  const getReviews = (id) => {
    setLoading(true);
    CourseReviewService.get(currentPage, id)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        if (response.data.success) {
          const payload = response.data.data;

          if (currentPage == 0) {
            setReviews(payload.page);
          } else {
            setReviews([...reviews, ...payload.page]);
          }

          setTotalPages(payload.totalPages);
          setCurrentPage(payload.currentPage);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  const loadMore = () => {
    setCurrentPage(currentPage + 1);
    getReviews(course.id);
  };

  useEffect(() => {
    getReviews(course.id);
  }, []);

  return (
    <div className="content pt-30 pb-30 white-bg">
      {isLoading ? (
        <Loader color="#ffc107" size="150px" />
      ) : reviews && reviews.length > 0 ? (
        reviews.map((item, idx) => {
          return (
            <div className="cource-review-box mb-30">
              <h4>{item.reviewer.name}</h4>
              <div className="rating">
                <span className="total-rating">{item.rate}</span>
                {Array.fill(5).map((item, idx) => {
                  return idx > item.rate ? (
                    <span className="fa fa-star-o"></span>
                  ) : (
                    <span className="fa fa-star"></span>
                  );
                })}
              </div>
              <div className="text">{item.description}</div>
            </div>
          );
        })
      ) : (
        <Error message="No Reviews" />
      )}

      {reviews && reviews.length > 0 ? (
        <div className="text-center mt-10">
          <Link
            to="#"
            onClick={loadMore}
            className="more orange readon2  ml-40"
          >
            View More
          </Link>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReviewPart;
