import React from "react";
import { Link } from "react-router-dom";

const MenuItems = (props) => {
  const path = window.location.pathname;

  return (
    <React.Fragment>
      <li className={path === "/" ? "current-menu-item" : ""}>
        <Link to="/">Home</Link>
      </li>
      <li className={path === "/about-us" ? "current-menu-item" : ""}>
        <Link to="/about-us">About us</Link>
      </li>
      <li className={path === "/courses" ? "current-menu-item" : ""}>
        <Link to="/courses">Courses</Link>
      </li>
      <li className={path === "/products" ? "current-menu-item" : ""}>
        <Link to="/products">Inventions</Link>
      </li>
      <li className={path === "/contact-us" ? "current-menu-item" : ""}>
        <Link to="/contact-us">Contact Us</Link>
      </li>
    </React.Fragment>
  );
};

export default MenuItems;
