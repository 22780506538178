import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import Header from "../components/Layout/Header/Header";
import Footer from "../components/Layout/Footer/Footer";
import Newsletter from "../components/Common/Newsletter";
import ScrollToTop from "../components/Common/ScrollTop";
import OffWrap from "../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../components/Common/Breadcumb";
import SearchModal from "../components/Layout/Header/SearchModal";
import AboutImage from "../assets/img/about2.png";
// Image
import favIcon from "../assets/img/fav.png";
import Logo from "../assets/img/logo/dark-logo.png";
import footerLogo from "../assets/img/logo/lite-logo.png";

import bannerbg from "../assets/img/breadcrumbs/2.jpg";
import { util } from "../utilities/util";
import Swal from "sweetalert2";
import { UserService } from "../services/UserService";
import Loader from "../components/Common/Loader";
import NaijaStates from "naija-state-local-government";
import { CourseService } from "../services/CourseService";
import Error from "../components/Error/Error";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { config } from "../utilities/configs";
import { CourseEnrollmentService } from "../services/CourseEnrollmentService";

const CourseRegister = () => {
  const [course, setCourse] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isFetching, setFetching] = useState(true);
  const [localAreas, setLocalAreas] = useState({ lgas: [] });
  const { id, type } = useParams();
  const [input, setInput] = useState({
    name: "",
    phoneNumber: "",
    emailAddress: "",
    address: "",
    state: "",
    lga: "",
    role: "STUDENT",
    password: "",
  });
  const [enrollment, setEnrollment] = useState({
    uuid: 0,
    crsuid: 0,
    amount: 0,
    paymentReference: "",
    paymentMethod: "",
    paymentResponse: "",
  });
  const flutterWaveconfig = {
    public_key: config.flutterwaveKey,
    tx_ref: Date.now(),
    amount: 0,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: input.emailAddress,
      phone_number: input.phoneNumber,
      name: input.name,
    },
    customizations: {
      title: "Course payment",
      description: "Payment for a course",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };
  const handleFlutterPayment = useFlutterwave(flutterWaveconfig);

  const getCourse = (id) => {
    setFetching(true);
    CourseService.getCourseById(id)
      .then((response) => {
        setFetching(false);
        if (response.data.success) {
          setCourse(response.data.data);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setFetching(false);
      });
  };

  const registerCourse = (user, paymentResponse) => {
    console.log('user',);
    console.log('paymentResponse',paymentResponse);

    setEnrollment({
      uuid: user.id,
      crsuid: course.id,
      amount: paymentResponse.amount,
      paymentReference: paymentResponse.flw_ref,
      paymentMethod: "Flutterwave",
      paymentResponse: paymentResponse,
    });

    console.log('enrollment',enrollment)
    //wait for a second and then register the course
    setTimeout(() => {
      CourseEnrollmentService.add(enrollment)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          Swal.fire({
            title: "Success!",
            text: "Course registration successful",
            icon: "success",
          });
          setTimeout(() => {
            window.location.href = "https://dashboard.syroltech.com/";
          }, 3000);
        } else {
          Swal.fire({
            title: "Oops!",
            text: response.data.message,
            icon: "error",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        let message =
          typeof err.response !== "undefined"
            ? err.response.data.message
            : err.message;
        console.warn("error", message);
        Swal.fire({
          title: "Oops!",
          text: message,
          icon: "error",
        });
      });
    }, 1000);
  };

  const registerUser = (paymentResponse) => {
    UserService.add(input)
      .then((response) => {
        if (response.data.success) {
          registerCourse(response.data.data, paymentResponse);
        } else {
          console.log(response.data)
          if (response.data.message.includes("exist")) {
            registerCourse(response.data.data, paymentResponse);
          } else {
            Swal.fire({
              title: "Oops!",
              text: response.data.message,
              icon: "error",
            });
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.warn("error", err);
        let message =
          typeof err.response !== "undefined"
            ? err.response.data.message
            : err.message;

            console.log(err.response.data)
        if (message.includes("exist")) {
          registerCourse(err.response.data.data, paymentResponse);
        } else {
          Swal.fire({
            title: "Oops!",
            text: message,
            icon: "error",
          });
        }
      });
  };

  const submit = () => {
    if (
      input.name !== "" &&
      input.emailAddress !== "" &&
      input.phoneNumber !== "" &&
      input.state !== "" &&
      input.lga !== "" &&
      input.password !== ""
    ) {
      if (!util.validateEmail(input.emailAddress)) {
        Swal.fire({
          title: "Validation Failed!",
          text: "Invalid email address",
          icon: "error",
        });
      } else {
        setLoading(true);
        let payableAmount = course.price;
        if (type === "quarter") payableAmount = Math.round(course.price / 3);
        if (type === "half") payableAmount = Math.round(course.price / 2);

        flutterWaveconfig.amount = payableAmount;
        flutterWaveconfig.customizations.title = course.title;
        flutterWaveconfig.customer.email = input.emailAddress;
        flutterWaveconfig.customer.name = input.name;
        flutterWaveconfig.customer.phone_number = input.phoneNumber;

        handleFlutterPayment({
          callback: (response) => {
            console.log(response);
            if (
              response.status === "completed" &&
              response.charge_response_code === "00"
            ) {
              registerUser(response);
            } else {
              Swal.fire({
                title: "Payment Failed!",
                text: response.status,
                icon: "error",
              });
            }
            closePaymentModal(); // this will close the modal programmatically
          },
          onClose: () => {
            Swal.fire({
              title: "Payment Cancelled!",
              text: "If you are unable to make the payment, you can reach out to us and we will assist you do it at our end",
              icon: "warning",
            });
          },
        });
      }
    } else {
      Swal.fire({
        title: "Validation Failed!",
        text: "All fields are required!",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    getCourse(id);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="pages"
        secondParentMenu="others"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="hello@syroltech.com"
        Location="Abuja, Nigeria"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="Register"
        pageName="Register"
        breadcrumbsImg={bannerbg}
      />
      {/* Register Start */}
      <div className="rs-about style1 pb-100">
        <div className="container">
          <div className=" register-section pt-100 pb-100 md-pt-80 md-pb-80 row">
            <div className="col-lg-6 pr-70 md-pr-14">
              <div className="register-box">
                <div className="sec-title mb-30">
                  <h2 className="title mb-10  text-center">Join Syrol</h2>
                  {isFetching ? (
                    <Loader size="100px" color="#ffc107" />
                  ) : course ? (
                    <div className="course-detail">
                      <h3>{course.title}</h3>
                      <h5>
                        {type === "quarter"
                          ? "Quarter Payable Amount:" +
                            util.formatPrice(Math.round(course.price / 3))
                          : type === "half"
                          ? "Half Payable Amount:" +
                            util.formatPrice(Math.round(course.price / 2))
                          : "Total Payable Amount:" +
                            util.formatPrice(course.price)}
                      </h5>
                    </div>
                  ) : (
                    <Error message="Oops! Unable to fetch course information1" />
                  )}
                </div>
                <div className="styled-form">
                  <div id="form-messages"></div>
                  <div id="contact-form">
                    <div className="row clearfix">
                      <div className="form-group col-lg-12 mb-25">
                        <input
                          type="text"
                          value={input.name}
                          onChange={(e) =>
                            setInput({ ...input, name: e.target.value })
                          }
                          placeholder="Full Name"
                          required
                        />
                      </div>

                      <div className="form-group col-lg-12">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email address "
                          value={input.emailAddress}
                          onChange={(e) =>
                            setInput({ ...input, emailAddress: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="form-group col-lg-12">
                        <input
                          type="text"
                          name="phoneNumber"
                          placeholder="Phone Number"
                          maxLength={11}
                          value={input.phoneNumber}
                          onChange={(e) =>
                            setInput({ ...input, phoneNumber: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="form-group col-lg-12">
                        <select
                          name="state"
                          value={input.state}
                          onChange={(e) => {
                            setInput({ ...input, state: e.target.value });
                            setLocalAreas(NaijaStates.lgas(e.target.value));
                          }}
                          required
                        >
                          <option value="">Select state...</option>
                          {NaijaStates.states().map((item, idx) => {
                            return <option key={idx}>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div className="form-group col-lg-12">
                        <select
                          name="lga"
                          value={input.lga}
                          onChange={(e) =>
                            setInput({ ...input, lga: e.target.value })
                          }
                          required
                        >
                          <option value="">Select lga...</option>
                          {localAreas.lgas.map((item, idx) => {
                            return <option key={idx}>{item}</option>;
                          })}
                        </select>
                      </div>

                      <div className="form-group col-lg-12">
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          value={input.password}
                          onChange={(e) =>
                            setInput({ ...input, password: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="form-group col-lg-12">
                        <input
                          type="text"
                          name="address"
                          placeholder="Home address "
                          value={input.address}
                          onChange={(e) =>
                            setInput({ ...input, address: e.target.value })
                          }
                          required
                        />
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-sm-12 text-center">
                        <button
                          type="submit"
                          className="readon register-btn"
                          onClick={isLoading ? null : submit}
                        >
                          {isLoading ? (
                            <Loader />
                          ) : (
                            <span className="txt">Continue to Payment</span>
                          )}
                        </button>
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-sm-12">
                        <div className="users">
                          Already have an account?
                          <Link to="https://dashboard.syroltech.com/login">
                            Sign In
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
              <div className="img-part">
                <img
                  className="about-main"
                  src={AboutImage}
                  alt="About Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
        titleClass="title mb-0 white-color"
      />
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
      <SearchModal />
    </React.Fragment>
  );
};

export default CourseRegister;
