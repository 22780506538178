import React from 'react';

import SectionTitle from '../../components/Common/SectionTitle';

// About Image
import AboutImage from '../../assets/img/about.jpg';

const AboutText = (props) => {

    return (
        <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">            
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                        <div className="img-part">
                            <img className="about-main" src={AboutImage} alt="About Image" />
                        </div>
                    </div>
                    <div className="col-lg-6 pr-70 md-pr-14">
                        <div className="about-content">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-44 md-mb-0"
                                subtitleClass="sub-title orange"
                                subtitle="About Syrol"
                                titleClass="title mb-16"
                                title="The Leading IT Services Provider in Africa"
                                descClass="bold-text mb-22"
                                description="Syrol Technologies is a potential and outstanding collection of well-talented and professional industry IT experts who are dedicated to providing scalable IT services not limited to software development,  and hands-on computer professional training."
                                secondDescClass="desc"
                                secondDescription="Large-scale innovations fueled by trends such as software applications, mobile technologies, and artificial intelligence continue to drive change not only in the IT industry but in every sector. The technology industry is growing at an undisputably high rate, changing the conventional way of living, improving the standard of living, and contributing to the overall economy of the world. According to research, computer science (CS) is the future of the job market. By 2024, there will be a projected 4.4 million jobs in computer and information technology occupations hence, firms are consistently growing to need tech-skilled staff in their organizations, which is why Syrol Technologies was established."
                            />
                            {/* Section Title End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutText;