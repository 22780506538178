import React, { useEffect, useState } from "react";

import eventImg1 from "../../assets/img/error.jpg";
import eventImg2 from "../../assets/img/error.jpg";
import eventImg3 from "../../assets/img/error.jpg";
import SingleEventTwo from "../../components/Events/SingleEventTwo";
import SectionTitle from "../../components/Common/SectionTitle";
import { NewsEventService } from "../../services/NewsEventService";
import Error from "../../components/Error/Error";
import Loader from "../../components/Common/Loader";

const NewsEventSection = () => {
  const [items, setItems] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const getNewsAndEvents = (page) => {
    NewsEventService.get(page)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const payload = response.data.data;
          setItems(payload.page);
          setTotalPages(payload.totalPages);
          setCurrentPage(payload.currentPage);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  useEffect(() => {
    getNewsAndEvents(currentPage);
  }, []);

  return (
    <div className="rs-event modify1 orange-style pb-20 md-pt-80 md-pb-80">
      <div className="container">
        {items.length > 0 ? (
          <div className="row y-middle mb-50 md-mb-30">
            <div className="col-md-6 sm-mb-30">
              <SectionTitle
                sectionClass="sec-title"
                subtitleClass="sub-title primary"
                titleClass="title mb-0"
                title="News & Event"
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="row">
          {isLoading ? (
            <Loader color="#ffc107" size="150px" />
          ) : items.length > 0 ? (
            items.map((item, idx) => {
              return idx < 6 ? (
                <div className="col-lg-4 col-md-6 mb-30">
                  <SingleEventTwo
                    eventClass="event-item"
                    eventImg={item.thumbnail}
                    eventDescription={item.description}
                    eventDate={new Date(item.createdAt).toDateString()}
                    eventTitle={item.title}
                  />
                </div>
              ) : (
                <></>
              );
            })
          ) : (
            <Error title="Oops!" message="News Not Available" />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsEventSection;
