import React from 'react';

import aboutImg from '../../assets/img/choose/home12/1.png';
import icon1 from '../../assets/img/choose/home12/icon/1.png';
import icon2 from '../../assets/img/choose/home12/icon/2.png';
import icon3 from '../../assets/img/choose/home12/icon/3.png';

import bgImg from '../../assets/img/bg/home12/why-learn-bg.jpg';

const bgStyle = {
    backgroundImage:`url(${bgImg})`
}

const WhyChooseUs = () => {

    return (
        <div className="why-choose-us style3" style={bgStyle}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-40">
                        <div className="img-part">
                            <img src={aboutImg} alt="" />                        
                        </div>
                    </div>
                    <div className="col-lg-6 pl-60 md-pl-14">
                        <div className="sec-title3 mb-30">
                            <h2 className=" title new-title margin-0 pb-14">Why Syrol</h2>
                            <div className="new-desc">We train people to live in more effective ways with advanced technologies as their careers.</div>
                        </div>
                        <div className="services-part mb-20">
                            <div className="services-icon">
                                <img src={icon1} alt="" />
                            </div>
                            <div className="services-text">
                                <h2 className="title"> Lower Learning Cost</h2>
                                <p className="services-txt"> We are the epitome of affordability, where our cost of tuition doesn't worry and you learn to the beam. </p>
                            </div>
                        </div> 
                        <div className="services-part mb-20">
                            <div className="services-icon">
                                <img src={icon2} alt="" />
                            </div>
                            <div className="services-text">
                                <h2 className="title"> Learn From  Industry Experts</h2>
                                <p className="services-txt"> You don't need to be born a genius but it's teachable to be a genius with Syrol tech experts it's achievable.</p>
                            </div>
                        </div> 
                        <div className="services-part">
                            <div className="services-icon">
                                <img src={icon3} alt="" />
                            </div>
                            <div className="services-text">
                                <h2 className="title">Job Placement</h2>
                                <p className="services-txt">  For job opportunities look no further, search no further, we provide and link you up with high-paying jobs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyChooseUs;