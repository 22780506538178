import React from "react";
import CTA from "./CtaSection.js";
import Faq from "./FaqSection.js";
import Team from "./TeamSection.js";
import About from "./AboutSection.js";
import Courses from "./CourseSection.js";
import Categories from "./CategoriesSection.js";
import Testimonial from "./TestimonialSection.js";
import ScrollToTop from "../../components/Common/ScrollTop/index.js";
import BannerDefault from "../../components/Banner/BannerDefault.js";
import AboutWork from "./AboutWorkSection.js";
import Counter from "./CounterSection.js";
import Service from "./ServiceSection.js";
import WhyChooseUs from "./WhyChooseUsSection.js";
import KidsInTechSection from "./KidsInTechSection.js";
import KidsCourses from "./KidsCourseSection.js";
import BrandThree from "../../components/Common/Brand/BrandThree.js";
import NewsEventSection from "./NewsEventSection.js";
import QuoteSection from "./QuoteSection.js";

const HomeMain = () => {
  return (
    <React.Fragment>
      <BannerDefault />
      <About />
      <Service />
      <Counter />
      <Categories />
      <KidsInTechSection />
      <KidsCourses />
      <WhyChooseUs />
      <AboutWork />
      <Courses />
      <CTA />
      <Faq />
      <Team />
      <Testimonial />
      <QuoteSection />
      <NewsEventSection />
      <BrandThree />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default HomeMain;
