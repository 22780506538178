import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import SectionTitle from "../../components/Common/SectionTitle";
import CourseSingleTwo from "../../components/Courses/CourseSingleTwo";
import bgImg from "../../assets/img/bg/home-8.jpg";
import { CourseService } from "../../services/CourseService";
import Error from "../../components/Error/Error";
import Loader from "../../components/Common/Loader";
import { util } from "../../utilities/util";
const bgStyle = {
  backgroundImage: `url(${bgImg})`,
  "background-size": "cover",
  "background-repeat": "no-repeat",
  "background-position": "center center",
};

const KidsCourses = () => {
  const [items, setItems] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const getCourses = (page) => {
    CourseService.get(page, "KIDS")
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const payload = response.data.data;
          setItems(payload.page);
          setTotalPages(payload.totalPages);
          setCurrentPage(payload.currentPage);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCourses(currentPage);
  }, []);

  return (
    <div
      className="rs-popular-courses style1 pt-94 pb-70 md-pt-64 md-pb-40"
      style={bgStyle}
    >
      <div className="container">
        <div className="row y-middle mb-50 md-mb-30">
          <div className="col-md-6 sm-mb-30">
            <SectionTitle
              sectionClass="sec-title"
              subtitleClass="sub-title primary"
              subtitle="Top Kids Courses"
              titleClass="title mb-0 fs-5"
              title="We have kids friendly experts who are ready to get your kids ready for the future"
            />
          </div>
          <div className="col-md-6">
            <div className="btn-part text-right sm-text-left">
              <Link to="/kids/courses" className="readon">
                View All Kids Courses
              </Link>
            </div>
          </div>
        </div>

        <div className="row">
          {isLoading ? (
            <Loader color="#ffc107" size="150px" />
          ) : items.length > 0 ? (
            items.map((item, idx) => {
              return idx < 3 ? (
                <div className="col-lg-4 col-md-6 mb-30">
                  <CourseSingleTwo
                    id={item.id}
                    courseClass="courses-item"
                    courseImg={item.thumbnail}
                    courseTitle={item.title}
                    coursePrice={util.formatPrice(item.price)}
                    courseCategory={item.duration}
                    userCount={Math.floor(Math.random() * 1000)}
                    userRating="05"
                  />
                </div>
              ) : (
                <></>
              );
            })
          ) : (
            <Error title="Oops!" message="Courses Not Available" />
          )}
        </div>
      </div>
    </div>
  );
};

export default KidsCourses;
