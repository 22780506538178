import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';


import ctaImg from '../../assets/img/software.jpg';

const CTA = () => {

    return (
        <div className="rs-cta section-wrap gray-bg">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="img-part">
                        <img src={ctaImg} alt="Image" />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="content">
                        <SectionTitle
                            sectionClass="sec-title mb-40"
                            titleClass="title"
                            title="Software Development"
                            descClass="desc"
                            description="In Syrol, We design & develop software in a way to get the desired results. Maximum results at minimum Cost prevailing in the Market. Stable and easy to maintain."
                        />
                        <div className="btn-part">
                            <Link className="readon banner-style uppercase" to="/contact-us">Get in Touch With Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CTA;