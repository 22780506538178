import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

const Faq = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  return (
    <div className="rs-faq-part style1 pt-100 pb-100 md-pt-70 md-pb-70">
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="YLN1Argi7ik"
        onClose={() => {
          openModal();
        }}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 padding-0">
            <div className="main-part">
              <div className="title mb-40 md-mb-14">
                <h2 className="text-part">Frequently Asked Questions</h2>
              </div>
              <div className="faq-content">
                <Accordion className="accordion-style1" preExpanded={"a"}>
                  <AccordionItem className="accordion-item" uuid="a">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        What are the requirements to study in Syrol?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Admission requirements vary by course but generally
                      include an internet connection, laptop, or equivalent, and
                      sometimes specific prerequisites like programming skills
                      or prior coursework may be required depending on the
                      course one is joining.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="b">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        Does Syrol offer free courses?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Yes, Syrol offers free courses to children 8 to 14 years
                      old and those with a disability, although one has to
                      undergo personality and background tests to qualify for
                      the free course.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="c">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        Does Syrol offer scholarships?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Yes, Syrol provides scholarships to eligible students
                      based on merit and financial need. And for sponsored
                      scholarships, keep an eye on our social media handles,
                      anytime a scholarship is available it is released for
                      people to apply.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="d">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        How to get Syrol to build an app for me
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Put together requirements in a document, contact us via
                      our email hello@syroltech.com or phone number or send us
                      message{" "}
                      <a
                        href="https://syroltech.com/contact-us"
                        className="text-danger"
                      >
                        here
                      </a>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="e">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        Can i study in Syrol online?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Yes, Syrol has an intensive learning management platform
                      that is available online, you can learn on the go, and
                      stay connected with your peers and instructors.
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="col-lg-6 padding-0">
            <div className="img-part media-icon">
              <a
                className="popup-videos"
                onClick={() => {
                  openModal();
                }}
              >
                <i className="fa fa-play"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
