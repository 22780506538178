import React from "react";
import { Link } from "react-router-dom";

import shapeImg1 from "../../assets/img/banner/home12/dotted-shape.png";
import shapeImg2 from "../../assets/img/banner/home12/intro-box.png";

import bgImg from "../../assets/img/banner/banner.png";

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const BannerDefault = () => {
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div id="rs-banner" className="rs-banner style10" style={bgStyle}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pl-60 relative order-last">
              <div className="img-part">
                <div className="mil-illustration-1">
                  <div className="mil-item mil-item-1">
                    <div className="mil-plus">
                      <div className="mil-hover-window">
                        <div className="mil-window-content">
                          <h5 className="mil-dark mil-mb-15">Dedicated Team</h5>
                          <div className="mil-divider mil-divider-left mil-mb-15"></div>
                          <p className="mil-text-sm">
                            Our team are always ready to service our clients efficiently
                          </p>
                        </div>
                      </div>
                      <div className="mil-item-hover">
                        <div className="mil-plus-icon">+</div>
                        <h6 className="mil-light">Dedicated Team</h6>
                      </div>
                    </div>
                  </div>
                  <div className="mil-item mil-item-2">
                    <div className="mil-plus">
                      <div className="mil-hover-window">
                        <div className="mil-window-content">
                          <h5 className="mil-dark mil-mb-15">Infrastructur</h5>
                          <div className="mil-divider mil-divider-left mil-mb-15"></div>
                          <p className="mil-text-sm">
                           We have comfortable development infrastructur and learning environment
                          </p>
                        </div>
                      </div>
                      <div className="mil-item-hover">
                        <div className="mil-plus-icon">+</div>
                        <h6 className="mil-light">Infrastructur</h6>
                      </div>
                    </div>
                  </div>
                  <div className="mil-item mil-item-3">
                    <div className="mil-plus">
                      <div className="mil-hover-window">
                        <div className="mil-window-content">
                          <h5 className="mil-dark mil-mb-15">Partners</h5>
                          <div className="mil-divider mil-divider-left mil-mb-15"></div>
                          <p className="mil-text-sm">
                         We have dedicated partners working with us to service you.
                          </p>
                        </div>
                      </div>
                      <div className="mil-item-hover">
                        <div className="mil-plus-icon">+</div>
                        <h6 className="mil-light">Partners</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pr-0">
              <div className="banner-content">
                <h1 className="sl-title">
                  The World Of Digital Transformation & Innovation
                </h1>
                <div className="sl-sub-title">
                  "Digital inclusion is your passport to the future, and
                  tomorrow belongs to those who prepare for it today." -CEO
                  Syrol
                </div>

                <div className="banner-btn ">
                  <Link className="readon green-banner" to="/courses">
                    Join Syrol
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-intro-box">
          <div className="shape-img">
            <img className="up-down-new" src={shapeImg1} alt="" />
          </div>
          <div className="intro-img">
            <img className="spine2" src={shapeImg2} alt="" />
          </div>
        </div>
      </div>
      {/* <!-- banner section end --> */}
    </React.Fragment>
  );
};

export default BannerDefault;
